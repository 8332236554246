import { useEffect, useState } from "react";
import { Card, message, Image, Avatar, Skeleton, Modal } from "antd";
import { api } from "src/services";
import Meta from "antd/lib/card/Meta";
import SpanPhone from "../span/SpanPhone";
import SpanMailTo from "../span/SpanMailTo";

const CardProfile = ({
  doctorArr,
  isModalOpenProfile,
  setIsModalCancelProfile,
}) => {
  const [linkQr, setListLinkQr]: any = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, [doctorArr]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response: any = await api.getQRLink<any>(
        doctorArr.bank_name,
        doctorArr.bank_number,
        doctorArr.bank_username,
        null,
        doctorArr.name,
        doctorArr.phone
      );
      if (response && response.data) {
        setListLinkQr(response.data);
      } else {
        message.error(response.message);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Modal
        title="Thông tin bác sĩ"
        centered
        open={isModalOpenProfile}
        onCancel={setIsModalCancelProfile}
        footer={null}
        className="modal-scroll profile-doctor-container"
      >
        <div>
          <div style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
            <Meta
              avatar={
                <Avatar src={doctorArr?.avatar || doctorArr?.avatar_url} />
              }
            />
          </div>

          <div className="info-group-profile">
            <div className="info-group-title">Họ và tên:</div>
            {doctorArr?.name ? (
              <div className="info-group-content">{doctorArr.name}</div>
            ) : null}
          </div>

          <div className="info-group-profile">
            <div className="info-group-title">Giới tính:</div>
            {doctorArr?.gender ? (
              <div className="info-group-content">
                {doctorArr.gender === "male" ? "Nam" : "Nữ"}
              </div>
            ) : null}
          </div>

          {doctorArr?.profile ? (
            <div className="info-group-profile">
              <div className="info-group-title">Thông tin profile:</div>
              <div className="info-group-content">
                <a href={doctorArr.profile.url} target="_blank">
                  xem chi tiết
                </a>
              </div>
            </div>
          ) : null}

          <div className="info-group-profile">
            <div className="info-group-title">Chuyên khoa:</div>
            {doctorArr?.specialist ? (
              <div className="info-group-content">{doctorArr.specialist}</div>
            ) : null}
          </div>

          <div className="info-group-profile">
            <div className="info-group-title">Số điện thoại:</div>
            {doctorArr?.phone ? (
              <div className="info-group-content">
                {<SpanPhone phone={doctorArr.phone} />}
              </div>
            ) : null}
          </div>

          <div className="info-group-profile">
            <div className="info-group-title">Email:</div>
            {doctorArr?.email ? (
              <div className="info-group-content">
                {<SpanMailTo mail={doctorArr.email} />}
              </div>
            ) : null}
          </div>

          <div className="info-group-profile">
            <div className="info-group-title">Tên bệnh viện:</div>
            {doctorArr?.hospital_name ? (
              <div className="info-group-content">
                {doctorArr.hospital_name}
              </div>
            ) : null}
          </div>

          <div className="info-group-profile">
            <div className="info-group-title">Địa chỉ</div>
            {doctorArr?.address ? (
              <div className="info-group-content">{doctorArr.address}</div>
            ) : null}
          </div>

          <div className="info-group-profile">
            <div className="info-group-title">Tên ngân hàng:</div>
            {doctorArr?.bank_name ? (
              <div className="info-group-content">{doctorArr.bank_name}</div>
            ) : null}
          </div>

          <div className="info-group-profile">
            <div className="info-group-title">Số tài khoản ngân hàng:</div>
            {doctorArr?.bank_number ? (
              <div className="info-group-content">{doctorArr.bank_number}</div>
            ) : null}
          </div>

          <div className="info-group-profile">
            <div className="info-group-title">Tên tài khoản ngân hàng:</div>
            {doctorArr?.bank_username ? (
              <div className="info-group-content">
                {doctorArr.bank_username}
              </div>
            ) : null}
          </div>
        </div>

        <Card
          title="QR Code thanh toán"
          size={"small"}
          style={{ marginTop: "1rem" }}
        >
          <Skeleton loading={loading} active>
            {linkQr?.link ? (
              <Image
                alt={linkQr.link}
                src={linkQr.link}
                style={{ width: "100%" }}
              />
            ) : (
              "Không tìm thấy QR Code thanh toán"
            )}
          </Skeleton>
        </Card>
      </Modal>
    </div>
  );
};

export default CardProfile;
