import { Button, Card, Col, Divider, Empty, List, Row } from "antd";
import { CardImageListNoDelete } from "src/components";

export default function PrescriptionDetail({ prescription, handleClickCopyPrescription }) {
  return (
    <div className="prescription-detail">
      {prescription && prescription.images.length ? (
        <>
          <CardImageListNoDelete
            loading={false}
            ArrImage={prescription.images}
          />
        </>
      ) : (
        <>
          <Row gutter={16}>
            <Col span={10}>
              <List
                size="small"
                header={
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      fill="none"
                    >
                      <path
                        d="M5.11875 0.940989C5.35937 1.66286 4.96836 2.4406 4.25078 2.68122L3.125 3.05505V8.24997C3.125 10.5273 4.97266 12.375 7.25 12.375C9.52734 12.375 11.375 10.5273 11.375 8.24997V3.05505L10.2535 2.68122C9.53164 2.4406 9.14492 1.66286 9.38555 0.940989C9.62617 0.219114 10.4039 -0.167604 11.1258 0.0730207L12.2473 0.446849C13.3687 0.820677 14.125 1.86911 14.125 3.05505V8.24997C14.125 11.5672 11.7789 14.3343 8.65508 14.9832C8.92578 17.3851 10.9625 19.25 13.4375 19.25C16.0973 19.25 18.25 17.0972 18.25 14.4375V11.3996C17.034 10.8711 16.1875 9.65935 16.1875 8.24997C16.1875 6.35076 17.7258 4.81247 19.625 4.81247C21.5242 4.81247 23.0625 6.35076 23.0625 8.24997C23.0625 9.65935 22.216 10.8711 21 11.3996V14.4375C21 18.614 17.6141 22 13.4375 22C9.4457 22 6.18008 18.9105 5.89648 14.9918C2.74687 14.3601 0.375 11.5843 0.375 8.24997V3.05505C0.375 1.87341 1.13125 0.820677 2.25703 0.446849L3.37852 0.0730207C4.10039 -0.167604 4.87812 0.223411 5.11875 0.940989ZM19.625 9.62497C19.9897 9.62497 20.3394 9.48011 20.5973 9.22225C20.8551 8.96438 21 8.61465 21 8.24997C21 7.8853 20.8551 7.53556 20.5973 7.2777C20.3394 7.01984 19.9897 6.87497 19.625 6.87497C19.2603 6.87497 18.9106 7.01984 18.6527 7.2777C18.3949 7.53556 18.25 7.8853 18.25 8.24997C18.25 8.61465 18.3949 8.96438 18.6527 9.22225C18.9106 9.48011 19.2603 9.62497 19.625 9.62497Z"
                        fill="#013178"
                      />
                    </svg>
                    <span className="ml-4px">
                      Chẩn đoán
                    </span>
                  </>
                }
                bordered
                dataSource={prescription.type_disease}
                renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
              />
            </Col>
            <Col span={10}>
              <Card size="small" title="Lời dặn" style={{ width: "100%", height: '100%' }}>
                { prescription.note || 'Không có dữ liệu'}
              </Card>
            </Col>
            <Col span={4}>
              <Card size="small" title="Copy toa thuốc" style={{ width: "100%", height: '100%' }}>
                <Button type="primary" onClick={handleClickCopyPrescription}>Copy</Button>
              </Card>
            </Col>
          </Row>
          <Divider />
          <Card size="small" title="Toa thuốc" style={{ width: "100%" }}>
            <embed
              src={prescription.file_pdf}
              width="100%"
              height="500"
              type="application/pdf"
            ></embed>
          </Card>
        </>
      )}
    </div>
  );
}
