import { List, Card, Skeleton, Divider, Row, Col, Empty } from "antd";
import { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

function ClinicalNotesDetail(props) {
  const [loading, setLoading] = useState(false);
  const { rPatitent } = props;

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <div className="admin-table">
      <div className="flex-fill">
        {rPatitent && rPatitent.images.length ? (
          <>
            <div>
              <Card
                size="small"
                style={{
                  marginBottom: 16,
                  height: "unset",
                }}
                title="Ảnh:"
              >
                <Skeleton loading={loading}>
                  <div>
                    <ImageList
                      variant="quilted"
                      cols={Math.min(rPatitent.images.length, 5)}
                      gap={16}
                      rowHeight={
                        rPatitent.images.length === 4
                          ? 150
                          : rPatitent.images.length === 3
                            ? 200
                            : rPatitent.images.length === 2
                              ? 250
                              : rPatitent.images.length === 1
                                ? 500
                                : 121
                      }
                    >
                      {rPatitent.images.map((item: any) => (
                        <div>
                          {item ? (
                            <ImageListItem
                              key={item}
                              cols={item.cols || 1}
                              rows={item.rows || 1}
                              className="list-images"
                            >
                              <img
                                {...srcset(item, 121, item.cols, item.rows)}
                                alt={item}
                                loading="lazy"
                                onClick={() => window.open(item)}
                              />
                            </ImageListItem>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </ImageList>
                  </div>
                </Skeleton>
              </Card>
            </div>
          </>
        ) : (
          <>
            <Row gutter={16}>
              <Col span={8}>
                <List
                  size="small"
                  style={{ width: "100%", height: '100%' }}
                  header={
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                        <path d="M3 3C3 1.89543 3.89543 1 5 1H14L20 7V19C20 20.1046 19.1046 21 18 21H5C3.89543 21 3 20.1046 3 19V3Z" stroke="#013178" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14 1V7H20" stroke="#013178" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <rect x="10" y="9" width="4" height="4" fill="#FF3E3E" />
                        <rect x="11" y="8" width="2" height="6" fill="#FF3E3E" />
                        <rect x="8" y="11" width="6" height="2" fill="#FF3E3E" />
                        <line x1="7" y1="15" x2="13" y2="15" stroke="#013178" stroke-width="2" stroke-linecap="round" />
                        <line x1="7" y1="18" x2="17" y2="18" stroke="#013178" stroke-width="2" stroke-linecap="round" />
                      </svg>
                      <span className="ml-4px">Ghi chú lâm sàng</span>
                    </>
                  }
                  bordered
                  dataSource={rPatitent.examination_results ? [rPatitent.examination_results] : []}
                  renderItem={(item: any) =>
                    item ? (
                      <List.Item>{item}</List.Item>
                    ) : (
                      <List.Item>
                        <Empty description="No Data" />
                      </List.Item>
                    )
                  }
                />
              </Col>
              <Col span={8}>
                <List
                  size="small"
                  style={{ width: "100%", height: '100%' }}
                  header={
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                        <path d="M3 3C3 1.89543 3.89543 1 5 1H14L20 7V19C20 20.1046 19.1046 21 18 21H5C3.89543 21 3 20.1046 3 19V3Z" stroke="#013178" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14 1V7H20" stroke="#013178" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <line x1="7" y1="13" x2="13" y2="13" stroke="#013178" stroke-width="2" stroke-linecap="round" />
                        <line x1="7" y1="17" x2="17" y2="17" stroke="#013178" stroke-width="2" stroke-linecap="round" />
                      </svg>
                      <span className="ml-4px">Ghi chú khác</span>
                    </>
                  }
                  bordered
                  dataSource={rPatitent.note ? [rPatitent.note] : []}
                  renderItem={(item: any) =>
                    item ? (
                      <List.Item>{item}</List.Item>
                    ) : (
                      <List.Item>
                        <Empty description="No Data" />
                      </List.Item>
                    )
                  }
                />
              </Col>
              <Col span={8}>
                <List
                  size="small"
                  style={{ width: "100%", height: '100%' }}
                  header={
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="22"
                        viewBox="0 0 24 22"
                        fill="none"
                      >
                        <path
                          d="M5.11875 0.940989C5.35937 1.66286 4.96836 2.4406 4.25078 2.68122L3.125 3.05505V8.24997C3.125 10.5273 4.97266 12.375 7.25 12.375C9.52734 12.375 11.375 10.5273 11.375 8.24997V3.05505L10.2535 2.68122C9.53164 2.4406 9.14492 1.66286 9.38555 0.940989C9.62617 0.219114 10.4039 -0.167604 11.1258 0.0730207L12.2473 0.446849C13.3687 0.820677 14.125 1.86911 14.125 3.05505V8.24997C14.125 11.5672 11.7789 14.3343 8.65508 14.9832C8.92578 17.3851 10.9625 19.25 13.4375 19.25C16.0973 19.25 18.25 17.0972 18.25 14.4375V11.3996C17.034 10.8711 16.1875 9.65935 16.1875 8.24997C16.1875 6.35076 17.7258 4.81247 19.625 4.81247C21.5242 4.81247 23.0625 6.35076 23.0625 8.24997C23.0625 9.65935 22.216 10.8711 21 11.3996V14.4375C21 18.614 17.6141 22 13.4375 22C9.4457 22 6.18008 18.9105 5.89648 14.9918C2.74687 14.3601 0.375 11.5843 0.375 8.24997V3.05505C0.375 1.87341 1.13125 0.820677 2.25703 0.446849L3.37852 0.0730207C4.10039 -0.167604 4.87812 0.223411 5.11875 0.940989ZM19.625 9.62497C19.9897 9.62497 20.3394 9.48011 20.5973 9.22225C20.8551 8.96438 21 8.61465 21 8.24997C21 7.8853 20.8551 7.53556 20.5973 7.2777C20.3394 7.01984 19.9897 6.87497 19.625 6.87497C19.2603 6.87497 18.9106 7.01984 18.6527 7.2777C18.3949 7.53556 18.25 7.8853 18.25 8.24997C18.25 8.61465 18.3949 8.96438 18.6527 9.22225C18.9106 9.48011 19.2603 9.62497 19.625 9.62497Z"
                          fill="#013178"
                        />
                      </svg>
                      <span className="ml-4px">
                        Chẩn đoán
                      </span>
                    </>
                  }
                  bordered
                  dataSource={rPatitent.type_diseases}
                  renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
}

export default ClinicalNotesDetail;


// Code cũ

// import { List, Card, Skeleton } from "antd";
// import { useState } from "react";
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";

// function ClinicalNotesDetail(props) {
//   const [loading, setLoading] = useState(false);
//   const { rPatitent } = props;

//   function srcset(image: string, size: number, rows = 1, cols = 1) {
//     return {
//       src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
//       srcSet: `${image}?w=${size * cols}&h=${
//         size * rows
//       }&fit=crop&auto=format&dpr=2 2x`,
//     };
//   }

//   return (
//     <div className="admin-table">
//       <div className="flex-fill">
//         <div>
//           <div>
//             <h3>Ghi chú lâm sàng: </h3>
//             {rPatitent.examination_results ? (
//               <p>{rPatitent.examination_results}</p>
//             ) : (
//               "Không tìm thấy thông tin"
//             )}
//           </div>

//           {/* <div>
//             <h3>Chỉ định xét nghiệm: </h3>
//             {rPatitent.type_tests ? (
//               <List
//                 dataSource={rPatitent.type_tests}
//                 renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
//               />
//             ) : (
//               "Không tìm thấy thông tin"
//             )}
//           </div> */}

//           <div>
//             <h3>Chẩn đoán: </h3>
//             {rPatitent.type_diseases ? (
//               <List
//                 dataSource={rPatitent.type_diseases}
//                 renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
//               />
//             ) : (
//               "Không tìm thấy thông tin"
//             )}
//           </div>

//           {rPatitent.note ? (
//             <div>
//               <h3>Ghi chú thêm:</h3>
//               <p>{rPatitent.note}</p>
//             </div>
//           ) : (
//             ""
//           )}
//           {rPatitent.images ? (
//             <div>
//               <Card
//                 size="small"
//                 style={{
//                   marginBottom: 16,
//                   height: "unset",
//                 }}
//                 title="Ảnh:"
//               >
//                 <Skeleton loading={loading}>
//                   <div>
//                     <ImageList
//                       variant="quilted"
//                       cols={Math.min(rPatitent.images.length, 5)}
//                       gap={16}
//                       rowHeight={
//                         rPatitent.images.length === 4
//                           ? 150
//                           : rPatitent.images.length === 3
//                           ? 200
//                           : rPatitent.images.length === 2
//                           ? 250
//                           : rPatitent.images.length === 1
//                           ? 500
//                           : 121
//                       }
//                     >
//                       {rPatitent.images.map((item: any) => (
//                         <div>
//                           {item ? (
//                             <ImageListItem
//                               key={item}
//                               cols={item.cols || 1}
//                               rows={item.rows || 1}
//                               className="list-images"
//                             >
//                               <img
//                                 {...srcset(item, 121, item.cols, item.rows)}
//                                 alt={item}
//                                 loading="lazy"
//                                 onClick={() => window.open(item)}
//                               />
//                             </ImageListItem>
//                           ) : (
//                             ""
//                           )}
//                         </div>
//                       ))}
//                     </ImageList>
//                   </div>
//                 </Skeleton>
//               </Card>
//             </div>
//           ) : (
//             ""
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClinicalNotesDetail;
