import { PlusOutlined } from "@ant-design/icons";
import { Modal, Button, Image, Upload, message, Card, Skeleton, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allActions } from "src/redux";
import { api } from "src/services";

const RefundConfirmationModal = ({
  isVisible,
  handleCancel,
  booking,
  fetchListPatitent
}) => {
  const [fileUpload, setFileUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [linkQr, setListLinkQr]: any = useState({});
  const dispatch = useDispatch();

  const handleFinishForm = async () => {
    if (!fileUpload.length) {
      return message.error("Vui lòng chọn hình ảnh/biên lai đã hoàn tiền cho bệnh nhân!");
    }

    let formData: any = new FormData();
    formData.append('payment_confirm', 'refunded');
    fileUpload.forEach(file => {
      formData.append('images[]', file.file);
    });

    try {
      setLoading(true);
      const response: any = await api.updateBookingPayment(booking?.id, formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success('Xác nhận hoàn tiền thành công!');
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      setFileUpload([]);
      fetchListPatitent();
      setLoading(false);
      handleCancel();
    }
  };

  useEffect(() => {
    booking?.member?.bank_name && booking?.member?.bank_number && booking?.member?.bank_username && booking?.member?.name &&
    booking?.member?.phone && fetchQrCode();
  }, []);

  const fetchQrCode = async () => {
    setLoading(true);
    try {
      const response: any = await api.getQRLink<any>(
        booking?.member?.bank_name,
        booking?.member?.bank_number,
        booking?.member?.bank_username,
        booking?.serivce?.price,
        booking?.member?.name,
        booking?.member?.phone
      );
      if (response && response.data) {
        setListLinkQr(response.data);
      } else {
        message.error(response.message);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleChat = async(user:any)=>{
    dispatch(allActions.option.saveOption(user));
  }

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onBeforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Bạn chỉ có thể tải lên tập tin JPG/PNG!");
      setFileUpload([]);
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Ảnh phải có dung lượng nhỏ hơn 2MB!");
      return false;
    }

    const fileObject = {
      name: file.name,
      size: file.size,
      url: null,
      file: file,
    };
    setFileUpload((prevFiles) => prevFiles.concat(fileObject));

    getBase64(file, (url: any) => {
      fileObject.url = url;
    });

    return false;
  };

  const onRemove = (file: any) => {
    const updatedFiles = fileUpload.filter((item) => item.name !== file.name);
    setFileUpload(updatedFiles);
  };

  return (
    <Modal
      title="Xác nhận đã hoàn tiền"
      open={isVisible}
      footer={null}
      onCancel={handleCancel}
      centered
      width={700}
    >
      <Row gutter={16}>
        <Col span={12}>
          {linkQr?.link ? 
            <Card
              title="QR Code thanh toán"
              size={"small"}
              style={{ marginTop: "1rem", height: '100%' }}
            >
              <Skeleton loading={loading} active>
                  <Image
                    alt={linkQr.link}
                    src={linkQr.link}
                    width={150}
                    height={150}
                    style={{ objectFit: 'cover' }}
                  />
              </Skeleton>
            </Card>
            :
            <Card
              title="Thông tin liên hệ"
              size={"small"}
              style={{ marginTop: "1rem", height: '100%' }}
            >
              <div className="info-group-profile">
                <div className="info-group-title">Tên khách hàng:</div>
                {booking?.member?.name ? (
                  <div className="info-group-content">{booking?.member?.name}</div>
                ) : null}
              </div>
              <div className="info-group-profile">
                <div className="info-group-title">Số điện thoại:</div>
                {booking?.member?.phone ? (
                  <div className="info-group-content">{booking?.member?.phone}</div>
                ) : null}
              </div>
              <div className="info-group-profile text-bold">
                <div className="info-group-title">Liên hệ qua tin nhắn:</div>
                <div className="info-group-content">
                  <Button
                    disabled={booking?.member.id ? false : true}
                    className="btn-intro-dr"
                    size="small"
                    type="primary"
                    title="Liên hệ"
                    onClick={()=> handleChat(booking?.member)}
                  >
                    Liên hệ
                  </Button>
                </div>
              </div>
            </Card>
          }
        </Col>
        <Col span={12}>
          <Card
            title="Hình ảnh/biên lai xác nhận thanh toán của bệnh nhân"
            size={"small"}
            style={{ marginTop: "1rem", height: '100%' }}
          >
            {
              booking?.images_payment_member?.length ?
                <div className="d-flex gap-1 flex-wrap">
                  <Image.PreviewGroup>
                    {booking?.images_payment_member?.map((item: any, index: number) => (
                      <Image style={{ objectFit: 'cover' }} width={150} height={150} src={item} key={index} />
                    ))
                    }
                  </Image.PreviewGroup>
                </div>
                :
                <div>Không có hình ảnh biên lai xác nhận thanh toán nào</div>
            }
          </Card>
        </Col>
      </Row>

      <div className="text-semibold mt-1 mb-1">Tải hình ảnh/biên lai xác nhận đã hoàn tiền</div>
      <Upload
        listType="picture-card"
        showUploadList={true}
        beforeUpload={onBeforeUpload}
        multiple={true}
        onRemove={onRemove}
        accept=".jpg, .jpeg, .png"
        fileList={fileUpload}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 4 }}>Upload</div>
        </div>
      </Upload>

      <div className="text-center mt-1">
        <Button type="primary" loading={loading} onClick={handleFinishForm}>
          Xác nhận đã hoàn tiền
        </Button>
      </div>
    </Modal>
  );
};

export default RefundConfirmationModal;
