import { useEffect } from "react";
import "antd/dist/antd.min.css";
import "./assets/sass/common.scss";
import { Switch, Route, useLocation, BrowserRouter } from "react-router-dom";
import { AuthenticationLayout, DefaultLayout } from "./layouts";
import {
  Dashboard,
  Login,
  MyPatient,
  PageOrderPatientTest,
  PasswordReset,
  Schedule,
  ScheduleDetail,
  SettingSchedule,
  ListService,
  TreatmentProtocol,
  Dictionary,
  Signature,
  SettingHoliday,
  HeadLetter,
  Medicine,
  TermsAndConditions,
  DoctorReferrers,
  ShareQRcode,
  PatientReferrers,
  AccountInformation,
  ChangePassword,
  ServiceResult,
  SamplePrescription,
  SampleOrderTest,
  News,
  NewsDetail,
  NewsCategory,
  // TermsAndConditions,
} from "./views";
import { AuthProvider } from "./context/AuthProvider";
import { Button, Result } from "antd";

const ScrollToTop = (props: any) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

function App() {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop>
        <AuthProvider>
          <Switch>
            <Route path="/auth">
              <AuthenticationLayout>
                <Route exact path="/auth/login" component={Login} />
                <Route path="/auth/password/reset" component={PasswordReset} />
              </AuthenticationLayout>
            </Route>
            <Route path="/">
              <DefaultLayout>
                <Route exact path="/" component={Dashboard} />
                <Route path="/schedules" component={Schedule} />
                <Route
                  exact
                  path="/my-patient/order-patient-test/:id"
                  component={PageOrderPatientTest}
                />
                <Route
                  exact
                  path="/order-patient-test/create"
                  component={PageOrderPatientTest}
                />
                {/* <Route
                  path="/my-patient/order-patient-test/:id"
                  component={PageOrderPatientTest}
                /> */}

                <Route exact path="/my-patient" component={MyPatient} />
                <Route
                  path="/my-patient/schedule-detail/:id"
                  component={ScheduleDetail}
                />
                <Route exact path="/service" component={ListService} />
                <Route exact path="/service-result" component={ServiceResult} />
                <Route
                  path="/service/setting/:id"
                  component={SettingSchedule}
                />

                <Route exact path="/dictionary" component={Dictionary} />
                <Route
                  exact
                  path="/TreatmentProtocol"
                  component={TreatmentProtocol}
                />
                <Route
                  exact
                  path="/termsandconditions"
                  component={TermsAndConditions}
                />

                <Route exact path="/dr/signature" component={Signature} />
                <Route exact path="/schedule/setting-holiday" component={SettingHoliday} />
                <Route exact path="/dr/headLetter" component={HeadLetter} />
                <Route
                  exact
                  path="/dr/sample-prescription"
                  component={SamplePrescription}
                />
                <Route
                  exact
                  path="/dr/sample-order-test"
                  component={SampleOrderTest}
                />
                <Route exact path="/medicine" component={Medicine} />

                <Route
                  exact
                  path="/doctor-referrers"
                  component={DoctorReferrers}
                />
                <Route
                  exact
                  path="/patient-referrers"
                  component={PatientReferrers}
                />
                <Route
                  path="/patient-referrers/schedule-detail/:id"
                  component={ScheduleDetail}
                />
                <Route
                  path="/patient-referrers/order-patient-test/:id"
                  component={PageOrderPatientTest}
                />
                <Route
                  path="/subclinical/order-patient-test/:id"
                  component={PageOrderPatientTest}
                />
                <Route exact path="/shareqrcode" component={ShareQRcode} />

                <Route
                  exact
                  path="/personal-information"
                  component={AccountInformation}
                />
                <Route
                  exact
                  path="/change-password"
                  component={ChangePassword}
                />
                <Route
                  exact
                  path="/news"
                  component={News}
                />
                <Route
                  path="/news/:id"
                  component={NewsDetail}
                />
                <Route
                  path="/news-category/:id"
                  component={NewsCategory}
                />
              </DefaultLayout>
            </Route>
            <Route path="*">
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary">Back Home</Button>}
              />
            </Route>
          </Switch>
        </AuthProvider>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
