import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function storeClinicSlot<T>(formData: any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/clinic-slot/create", formData)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getClinicSlot<T>(
  serivce_id: number,
  date: string
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/clinic-slot?date=${date}&service_id=${serivce_id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function updateClinicSlot<T>(
  id: number,
  formData: any
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/clinic-slot/edit/${id}`, formData)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
function deleteClinicSlot<T>(
  id: number,
  formData: { date: string; repeat: "daily" | "onlyday" }
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/clinic-slot/${id}`, { data: formData })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function setDoctorOffDays<T>(formData: any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/set-doctor-off-days", formData)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getDoctorDayOff<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/load-doctor-holidays`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getHolidays<T>(years: string): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/get-holidays?year=${years}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteHoliday<T>(
  id: number,
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/holiday-delete/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getClinicSlot,
  storeClinicSlot,
  updateClinicSlot,
  deleteClinicSlot,
  setDoctorOffDays,
  getDoctorDayOff,
  getHolidays,
  deleteHoliday
};

export default data;
