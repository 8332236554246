import {
  Col,
  Row,
  Spin,
  Table,
  message,
  Form,
  Button,
  Tooltip
} from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { format } from "date-fns";
import {
  AddButton,
  BreadcrumbComponent,
  CustomCalendarMultiSelect,
  FormatDate,
  IconDelete
} from "src/components";
import DeleteHolydayModal from "src/components/Schedule/Setting/DeleteHolidayModal";
export default function SettingHoliday() {
  const [loading, setLoading] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteForm = Form.useForm()[0];
  const moment = require('moment');

  const fetchHoliday = async () => {
    try {
      setLoading(true);
      const { data: rData }: { data: any } = await api.getDoctorDayOff();
      if (rData) {
        setHolidays(rData);
        const formattedDates = rData.map((item) =>
          format(new Date(item.date), "yyyy-MM-dd")
        );

        const currentYear = moment().year();
        const nextYear = currentYear + 1;
        // require('moment-lunar');

        // // Hàm chuyển đổi ngày âm lịch thành ngày dương lịch
        // const getSolarDateByLunarDate = (year, month, day) => {
        //   // Chuyển đổi ngày âm lịch sang ngày dương lịch
        //   return moment().lunar().year(year).month(month - 1).date(day).solar().format('YYYY-MM-DD');
        // };

        // const generateHolidays = (year) => [
        //   // ...[1, 2, 3, 4, 5].map(day => getSolarDateByLunarDate(year + 1, 1, day)), // Tết nguyên đán
        //   `${year}-01-01`, // Tết dương lịch
        //   `${year}-04-30`, // Chiến thắng Điện Biên Phủ
        //   `${year}-05-01`, // Quốc tế lao động
        //   `${year}-09-02`, // Quốc khánh
        //   getSolarDateByLunarDate(year, 3, 10), // Giỗ Tổ Hùng Vương
        // ];

        // const holidays = [
        //   ...generateHolidays(currentYear),
        //   ...generateHolidays(nextYear),
        // ];

        const holidays = await getHolidays([currentYear, nextYear]);

        const allDates = Array.from(new Set([...holidays, ...formattedDates]));
        setSelectedDates(allDates);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getHolidays = async (years) => {
    try {
      const response: any = await api.getHolidays(years.join(','));
      if (!response.error) {
        return response.data || [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    fetchHoliday();
  }, []);

  const breadcrumbItems = [
    { path: "/", name: "Trang chủ" },
    { path: "#", name: "Cài đặt ngày nghỉ" },
  ];

  const addHoliday = async () => {
    try {
      setLoading(true);
      const holidayDates = new Set(holidays.map(holiday => moment(holiday.date).startOf('day').format('YYYY-MM-DD')));
      const newSelectedDates = selectedDates.filter(date =>
        !moment(date).startOf('day').isBefore(moment().startOf('day'), 'day') || holidayDates.has(date)
      );
      const formData = { dates: newSelectedDates, call_from_web: true };
      const response: any = await api.setDoctorOffDays(formData);
      if (!response.error) {
        return response.data || [];
      }
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    } finally {
      fetchHoliday();
    }
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Tên bác sĩ",
      dataIndex: "name",
      key: "name",
      width: 100,
      render: (text, record) => record.member?.name || "Không có",
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    //   width: 100,
    //   render: (text, record) => record.member?.email || "Không có",
    // },
    // {
    //   title: "Số điện thoại",
    //   dataIndex: "phone",
    //   key: "phone",
    //   width: 100,
    //   render: (text, record) => record.member?.phone || "Không có",
    // },
    {
      title: "Ngày nghỉ",
      dataIndex: "date",
      key: "date",
      width: 100,
      render: (text, record) => (
        <div>{<FormatDate date={record.date} />}</div>
      ),
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_at",
      key: "created_at",
      width: 100,
      render: (text, record) => (
        <div>
          {record.created_at
            ? format(new Date(record.created_at), "HH:mm dd-MM-yyyy")
            : "Không có dữ liệu"}
        </div>
      ),
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 100,
      render: (text, record) => (
        <div>
          {record.created_at
            ? format(new Date(record.updated_at), "HH:mm dd-MM-yyyy")
            : "Không có dữ liệu"}
        </div>
      ),
    },
    {
      title: "Hành động",
      width: 15,
      key: "action",
      render: (text: string, record: any) => {
        const isPastDate = new Date(record.date) < new Date();
    
        const buttonContent = (
          <Button
            danger
            type="primary"
            onClick={() => {
              if (!isPastDate) {
                setDeleteModal(true);
                deleteForm.resetFields();
                deleteForm.setFieldsValue({ id: record.id });
              }
            }}
            disabled={isPastDate}
            style={{ margin: "0.5rem 0" }}
          >
            Xóa
          </Button>
        );
    
        return (
          <div>
            {isPastDate ? <Tooltip title="Không thể xóa vì ngày đã qua">{buttonContent}</Tooltip> : buttonContent}
          </div>
        );
      },
    }    
  ];

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleCancel = () => {
    setDeleteModal(false);
  };

  const handleDelete = async (record) => {
    try {
      setIsDeleting(true);

      const response: any = await api.deleteHoliday(record.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    } finally {
      fetchHoliday();
      handleCancel();
      setIsDeleting(false);
    }
  };


  return (
    <div className="admin-table">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="font-bold" style={{ marginBottom: "1rem" }}>
            Chọn ngày nghỉ để tắt lịch khám của ngày đó
          </div>
          <Spin spinning={loading}>
            <CustomCalendarMultiSelect
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              weekendActive={true}
              disablePastDates={true}
            />
          </Spin>
          <div style={{ marginTop: "1rem", textAlign: "end" }}>
            <AddButton
              onClick={() => {
                addHoliday();
              }}
              title={"Lưu ngày nghỉ"}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="font-bold" style={{ marginBottom: "1rem" }}>
            Danh sách ngày nghỉ
          </div>
          <Table
            columns={columns}
            dataSource={holidays}
            loading={loading}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <DeleteHolydayModal
        open={isDeleteModal}
        onCancel={handleCancel}
        onFinish={handleDelete}
        form={deleteForm}
        isDeleting={isDeleting}
      />
    </div>
  );
}
