import { useState, useEffect } from "react";
import { Calendar, Select, Row, Col, Tooltip, Button } from "antd";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import locale from "antd/lib/date-picker/locale/vi_VN";
import moment from "moment";
import 'moment-lunar';

const CustomCalendarMultiSelect = ({
  selectedDates,
  setSelectedDates,
  weekendActive = false,
  disablePastDates = false,
}) => {
  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const toggleDateSelection = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setSelectedDates((prevDates) =>
      prevDates.includes(formattedDate)
        ? prevDates.filter((d) => d !== formattedDate)
        : [...prevDates, formattedDate]
    );
  };

  useEffect(() => {
    if (!weekendActive) return;

    let weekendDates = [];

    selectedYear.forEach((year) => {
      if (selectedMonths.length === 0) {
        const firstDayOfYear = moment(`${year}-01-01`);
        const lastDayOfYear = moment(`${year}-12-31`);

        for (
          let date = firstDayOfYear;
          date.isBefore(lastDayOfYear) || date.isSame(lastDayOfYear);
          date.add(1, "day")
        ) {
          if (date.day() === 0 || date.day() === 6) {
            weekendDates.push(date.format("YYYY-MM-DD"));
          }
        }
      } else {
        selectedMonths.forEach((month) => {
          const firstDayOfMonth = moment(`${year}-${month}-01`);
          const lastDayOfMonth = moment(firstDayOfMonth).endOf("month");

          for (
            let date = firstDayOfMonth;
            date.isBefore(lastDayOfMonth) || date.isSame(lastDayOfMonth);
            date.add(1, "day")
          ) {
            if (date.day() === 0 || date.day() === 6) {
              weekendDates.push(date.format("YYYY-MM-DD"));
            }
          }
        });
      }
    });

    // Lọc các ngày trong quá khứ (giữ nguyên)
    const pastDates = selectedDates.filter((date) =>
      moment(date).isBefore(moment(), "day")
    );

    // Chỉ lấy các ngày hiện tại và tương lai
    const futureOrTodayWeekendDates = weekendDates.filter((date) =>
      moment(date).isSameOrAfter(moment(), "day")
    );

    const futureOrTodayWeekdaysOnly = selectedDates.filter((date) => {
      const day = moment(date).day();
      return day !== 0 && day !== 6 && moment(date).isSameOrAfter(moment(), "day");
    });

    // Hợp nhất các ngày: quá khứ (giữ nguyên) + hiện tại và tương lai (cập nhật)
    setSelectedDates(
      Array.from(
        new Set([...pastDates, ...futureOrTodayWeekdaysOnly, ...futureOrTodayWeekendDates])
      )
    );
  }, [selectedYear, selectedMonths, weekendActive]);

  const clearSelectedDates = () => {
    const pastDates = selectedDates.filter((date) =>
      moment(date).isBefore(moment(), "day") // Chỉ lấy các ngày trước hôm nay
    );

    setSelectedDates(pastDates);
    setSelectedYear([]);
    setSelectedMonths([]);
  };

  const getLunarDate = (date) => {
    // Tạo một bản sao của date trước khi gọi lunar()
    const clonedDate = date.clone();
    return clonedDate.lunar().format("D"); // Chỉ dùng để hiển thị
  };

  return (
    <div>
      {weekendActive && (
        <Row gutter={[16, 16]} style={{ marginBottom: "1rem" }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Select
              mode="multiple"
              placeholder="Chọn cuối tuần của năm"
              onChange={(value) => setSelectedYear(value)}
              style={{ width: "100%" }}
              value={selectedYear}
            >
              {Array.from({ length: 2 }, (_, i) => new Date().getFullYear() + i).map(
                (year) => (
                  <Select.Option key={year} value={year}>
                    {year}
                  </Select.Option>
                )
              )}
            </Select>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Select
              mode="multiple"
              placeholder="Chọn cuối tuần của tháng"
              onChange={(values) => setSelectedMonths(values)}
              style={{ width: "100%" }}
              value={selectedMonths}
            >
              {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                <Select.Option key={month} value={month}>
                  {moment().month(month - 1).format("MMMM")}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      )}

      <Calendar
        locale={locale}
        fullscreen={false}
        dateFullCellRender={(date) => {
          const formattedDate = date.clone().format("YYYY-MM-DD");
          const isSelected = selectedDates.includes(formattedDate);
          const isDisabled = disablePastDates && date.isBefore(moment(), "day");
          const isSaturday = date.day() === 6; // Kiểm tra thứ Bảy
          const isSunday = date.day() === 0; // Kiểm tra Chủ Nhật

          const lunarDate = getLunarDate(date);
          return (
            <div
              className={`ant-picker-cell-inner ${isSelected ? "ant-picker-cell-selected" : ""} ${isDisabled ? "ant-picker-cell-disabled" : ""}`}
              onClick={() => {
                if (!isDisabled) toggleDateSelection(date.clone());
              }}
              style={{
                cursor: isDisabled ? "not-allowed" : "pointer",
                borderRadius: "10%",
                backgroundColor: isSelected ? "#1890ff" : "transparent",
                color: isDisabled
                  ? "#ccc"
                  : isSelected
                    ? "#fff"
                    : isSaturday
                      ? "orange"  // Thứ Bảy màu cam
                      : isSunday
                        ? "red"     // Chủ Nhật màu đỏ
                        : "inherit", // Các ngày khác không thay đổi màu
                padding: "20px",
                border: "1px solid #CCCDD0",
              }}
            >
              <div
                style={{
                  fontSize: "1.5em",
                  position: "absolute",
                  top: "0%",
                  left: "10%",
                }}
              >
                {date.date()}
              </div>
              <div
                style={{
                  fontSize: "1em",
                  position: "absolute",
                  bottom: "-5%",
                  right: "10%",
                }}
              >
                {lunarDate}
              </div>
            </div>
          );
        }}
        headerRender={({ value, onChange }) => {
          const currentYear = value.year();
          const currentMonth = value.month();

          const years = Array.from(
            { length: 11 },
            (_, i) => new Date().getFullYear() + i - 1
          );

          return (
            <Row justify="space-between" align="middle" style={{ padding: "0 10px" }}>
              <Col xs={2} sm={2} md={1} lg={1} style={{ textAlign: "left" }}>
                <Tooltip title="Năm trước">
                  <DoubleLeftOutlined
                    onClick={() => onChange(value.clone().subtract(1, "year"))}
                  />
                </Tooltip>
              </Col>
              <Col xs={2} sm={2} md={1} lg={1} style={{ textAlign: "left" }}>
                <Tooltip title="Tháng trước">
                  <LeftOutlined
                    onClick={() => onChange(value.clone().subtract(1, "month"))}
                  />
                </Tooltip>
              </Col>
              <Col xs={6} sm={6} md={6} lg={4} style={{ textAlign: "center" }}>
                <Select
                  size="small"
                  value={currentMonth}
                  onChange={(newMonth) => onChange(value.clone().month(newMonth))}
                  style={{ width: "100%" }}
                >
                  {moment.months().map((month, i) => (
                    <Select.Option key={i} value={i}>
                      {month}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={6} sm={6} md={6} lg={4} style={{ textAlign: "center" }}>
                <Select
                  size="small"
                  value={currentYear}
                  onChange={(newYear) => onChange(value.clone().year(newYear))}
                  style={{ width: "100%" }}
                >
                  {years.map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={2} sm={2} md={1} lg={1} style={{ textAlign: "right" }}>
                <Tooltip title="Tháng sau">
                  <RightOutlined
                    onClick={() => onChange(value.clone().add(1, "month"))}
                  />
                </Tooltip>
              </Col>
              <Col xs={2} sm={2} md={1} lg={1} style={{ textAlign: "right" }}>
                <Tooltip title="Năm sau">
                  <DoubleRightOutlined
                    onClick={() => onChange(value.clone().add(1, "year"))}
                  />
                </Tooltip>
              </Col>
              <Col xs={24} sm={12} md={8} lg={4} style={{ margin: "0.5rem 0" }}>
                <Button onClick={clearSelectedDates} type="primary" size="small" style={{ width: "100%" }}>
                  Làm sạch
                </Button>
              </Col>
            </Row>
          );
        }}
      />
    </div>
  );
};

export default CustomCalendarMultiSelect;
