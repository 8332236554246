import { DeleteOutlined } from "@ant-design/icons";
import React from "react";

function IconDelete({ onClick, disabled = false }) {
  return (
    <span className="icon-container">
      <DeleteOutlined
        disabled={disabled}
        style={{ color: disabled ? "lightgray" : "red" }} 
        onClick={onClick}
        title={disabled ? "Không thể xóa" : "Xóa"}
      />
    </span>
  );
}

export default IconDelete;
